<template>
  <div class="appformMobile">
    <div class="title">
      <img
        src="../../assets/image/logo.png"
        style="width: 200px; margin-right: 12px"
      />
    </div>
    <div class="title1">Pre-registration</div>
    <el-form :model="form" :rules="formRules" ref="form" label-position="left">
      <div class="row">
        <div class="item" v-if="showEntry">
          <el-form-item label="Project Code" prop="project_code">
            <div class="input">
              <!-- <el-select v-model="form.project_code" placeholder="Please choose project" style="width:100%" :popper-append-to-body="false" popper-class="customSelect">
                                  <el-option value="22-23PIE"></el-option>
                              </el-select> -->
              <el-input
                v-model="entry.project_code"
                :disabled="entry_id"
              ></el-input>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="item" v-if="showEntry">
          <el-form-item label="Entry Name" prop="entry_name">
            <div class="input">
              <el-input v-model="entry.name" :disabled="entry_id"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>

      <!-- <div class="row">
                  <div class="item">
                      <el-form-item label="Have you already joined a team?" prop="ready">
                          <div class="input">
                              <img src="../../assets/image/choose.svg" v-if="form.ready==='Sure'">
                              <img src="../../assets/image/nochoose.svg" v-else @click="form.ready='Sure'">Sure
                              <img src="../../assets/image/choose.svg" style="margin-left:37px" v-if="form.ready==='No'">
                              <img src="../../assets/image/nochoose.svg" v-else style="margin-left:37px" @click="form.ready='No'">No
                          </div>
                      </el-form-item>
                  </div>
              </div> -->
      <!-- <div class="row">
                <div class="item">
                      <el-form-item label="The Way of Teaming up" prop="way">
                          <div class="input">
                              <el-select v-model="form.way" style="width:100%" :popper-append-to-body="false" popper-class="customSelect">
                                  <el-option value="Team Up with Schoolmates"></el-option>
                                  <el-option value="Team Up with Students from the Same Institution"></el-option>
                                  <el-option value="Friends (We are friends from different schools and do not belong to the same institution)"></el-option>
                              </el-select>
                          </div>
                      </el-form-item>
                  </div>
              </div> -->
      <div class="row">
        <div class="item">
          <el-form-item label="School Name in Native Language" prop="school">
            <div class="input">
              <el-input v-model="form.school" :disabled="account_id"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Country Where Your School is Located"
            prop="country"
          >
            <div class="input">
              <el-input
                v-model="form.country"
                :disabled="account_id"
              ></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row" v-if="account && account.account_type">
        <div class="item">
          <el-form-item label="School Name">
            <div class="input">
              <el-input v-model="form.ownSchool"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Last Name in Native Language"
            prop="lastName"
          >
            <div class="input">
              <el-input v-model="form.lastName"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Given Name in Native Language"
            prop="givenName"
          >
            <div class="input">
              <el-input v-model="form.givenName"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Last Name in English on the Passport or the ID Card"
            prop="lastNameEn"
          >
            <div class="input">
              <el-input v-model="form.lastNameEn"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Your Given Name in English on the Passport or the ID Card"
            prop="givenNameEn"
          >
            <div class="input">
              <el-input v-model="form.givenNameEn"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Gender" prop="gender">
            <div class="input">
              <img
                src="../../assets/image/choose.svg"
                v-if="form.gender === 'Male'"
              />
              <img
                src="../../assets/image/nochoose.svg"
                v-else
                @click="form.gender = 'Male'"
              />Male
              <img
                src="../../assets/image/choose.svg"
                style="margin-left: 37px"
                v-if="form.gender === 'Female'"
              />
              <img
                src="../../assets/image/nochoose.svg"
                v-else
                style="margin-left: 37px"
                @click="form.gender = 'Female'"
              />Female
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Graduation" prop="grade">
            <div class="input">
                <el-select
                  v-model="form.grade"
                  :popper-append-to-body="false"
                  popper-class="customSelect"
                >
                  <el-option v-for="(year,index) in years" :value="year" :key="index" :label="year"></el-option>
                </el-select>
              </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Contact Number (Mobile)" prop="mobile">
            <div class="input">
              <el-input v-model="form.mobile"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Email Address" prop="email">
            <div class="input">
              <el-input v-model="form.email"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Guardian Contact Number (Mobile)" prop="gMobile">
            <div class="input">
              <el-input v-model="form.gMobile"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Guardian Email Address" prop="gEmail">
            <div class="input">
              <el-input v-model="form.gEmail"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item
            label="Adviser’s Name（ Advisers will receive notification emails during the process. You can write none if you do not have one yet.）"
          >
            <div class="input">
              <el-input v-model="form.adiviserName"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Adviser’s Affiliation">
            <div class="input">
              <el-input v-model="form.adiviserAff"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Adviser’s Phone Number">
            <div class="input">
              <el-input v-model="form.adiviserMobile"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label="Adviser’s Email Address">
            <div class="input">
              <el-input v-model="form.adiviserEmail"></el-input>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="submit">
      <div class="submitBtn" @click="submit">Submit</div>
    </div>
  </div>
</template>

<script>
import { saveAppForm, addStudentInDatabase, getAccount } from "../../api/eae";
import "../../assets/common/common.css";

export default {
  data() {
    return {
      account_id: this.$route.query.account_id,
      entry_id: this.$route.query.entry_id,
      form: {
        ready: "",
        way: "",
        school: "",
        country: "",
        lastName: "",
        givenName: "",
        lastNameEn: "",
        givenNameEn: "",
        gender: "Male",
        grade: "",
        mobile: "",
        email: "",
        gMobile: "",
        gEmail: "",
        adiviserName: "",
        adiviserAff: "",
        adiviserMobile: "",
        adiviserEmail: "",
        project_code: "23-24EAE",
        ownSchool: "",
      },
      years:[],
      entry: {},
      showEntry: false,
      formRules: {
        // ready: [
        //   { required: true, message: 'Please choose', trigger: 'change' }
        // ],
        // project_code: [
        //   { required: true, message: 'Please choose', trigger: 'change' }
        // ],
        // way: [
        //   { required: true, message: 'Please choose', trigger: 'change' }
        // ],
        school: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        givenName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastNameEn: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        givenNameEn: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        gender: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        grade: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        email: [{ required: true, message: "Please input", trigger: "change" }],
        gMobile: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        gEmail: [
          { required: true, message: "Please input", trigger: "change" },
        ],
      },
      account: {},
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.unit_id) {
      let uid = this.$route.query.unit_id;
      if (uid != "") {
        this.account_id = this.$route.query.unit_id;
      }
    }

    if (this.account_id) {
      getAccount(this.account_id).then((res) => {
        if (res.data.code === 0) {
          this.account = res.data.data;
          this.form.school = res.data.data.account_name;
          this.form.country = res.data.data.account_country;
        }
      });
    }

    let current_year = new Date().getFullYear()
    let start_year = current_year

    for(let i=0;i<6;i++){
      let year = start_year+i
        this.years.push(year)
    }

    this.showEntry = false;

    // if (this.entry_id) {
    //   this.showEntry = true

    //   getMREntryById(this.entry_id).then(res => {
    //     if (res.data.code === 0) {
    //       this.entry = res.data.data
    //     }
    //   })
    // }
  },
  methods: {
    submit() {
      this.$refs.form.validate((val) => {
        if (val) {
          if (this.account_id) {
            if (
              this.entry_id === "" ||
              this.entry_id === undefined ||
              this.entry_id == null
            ) {
              this.entry_id = "";
            }

            addStudentInDatabase(
              this.account_id,
              this.entry_id,
              this.form.lastName,
              this.form.givenName,
              this.form.lastNameEn,
              this.form.givenNameEn,
              this.form.gender,
              this.form.grade,
              "impact",
              this.form.mobile,
              this.form.email
            ).then((res) => {
              if (res.data.code === 0) {
                this.$confirm(
                  "You have successfully registered",
                  "Registration Successful",
                  {
                    confirmButtonText: "OK, Proceed to the login page",
                    cancelButtonText: "Cancel",
                    type: "success",
                    customClass: "mobileConfirm",
                  }
                ).then(() => {
                  this.$router.push("/login");
                });
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            saveAppForm(this.form).then((res) => {
              if (res.data) {
                this.$confirm("Submit Successful", "Tips", {
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                  type: "success",
                  customClass: "mobileConfirm",
                });
              }
            });
          }
        } else {
          this.$confirm("Please fill in the required information", "Tips", {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            customClass: "mobileConfirm",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.appformMobile {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  font-family: "DDINAlternateRegular";
  overflow-y: scroll;
  background: #fff;
  .title {
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ff6450;
    font-size: 22px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }
  .title1 {
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ff6450;
    font-size: 22px;
    margin-bottom: 50px;
    //   display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 480px;
      .label {
        font-size: 16px;
        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #666666;
      }
      .input {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 18px;

        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #333333;
        img {
          margin-right: 24px;
          cursor: pointer;
        }
      }
    }
  }

  .submit {
    .submitBtn {
      width: 90%;
      height: 50px;
      background: #0e4890;
      border-radius: 25px;
      text-align: center;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      margin: 0 auto;
      margin-top: 84px;
      font-weight: bold;
      cursor: pointer;
    }
    margin-bottom: 50px;
  }
}
</style>
<style lang="scss">
.appformMobile {
  .el-input__inner {
    border-radius: 6px;
    border: 2px solid #717171;
    font-size: 18px;
  }
  .el-form-item__label {
    font-size: 16px;
    font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
    font-weight: normal;
    color: #666666;
    line-height: 30px;
    margin-bottom: 6px;
  }
  .customSelect {
    width: 100%;
  }
}
.mobileConfirm {
  width: 80%;
  .el-message-box__container {
    display: flex;
    .el-message-box__status {
      position: relative;
      flex-shrink: 0;
      transform: none;
    }
    .el-message-box__message {
      padding-left: 10px;
    }
  }
}
</style>
